<template>
  <div>
    <q-form ref="editForm">
      <c-card title="상세" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-if="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="data"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
            <c-text
              :disabled="true"
              :editable="editable"
              label="업체명"
              name="vendorName"
              v-model="data.vendorName">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
            <c-text
              :disabled="true"
              :editable="editable"
              label="업체코드"
              name="vendorCd"
              v-model="data.vendorCd">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
            <c-text
              :disabled="true"
              :editable="editable"
              :bizNumberCheck="true"
              label="사업자번호"
              name="bizNo"
              v-model="data.bizNo">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
            <c-select
              codeGroupCd="VENDOR_CLASS_CD"
              :required="true"
              :editable="false"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="vendorClassCd"
              label="업체분류"
              v-model="data.vendorClassCd"
            ></c-select>
          </div>
          <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
            <c-select
              codeGroupCd="VENDOR_TYPE_CD"
              :required="true"
              :editable="false"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="vendorTypeCd"
              label="업체유형"
              v-model="data.vendorTypeCd"
            ></c-select>
          </div>
          <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
            <c-text
              :editable="editable"
              label="대표자명"
              name="representName"
              v-model="data.representName">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
            <c-text
              :editable="editable"
              :phoneNumberCheck="true"
              label="대표전화번호"
              name="phoneNo"
              v-model="data.phoneNo">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
            <c-text
              :editable="editable"
              label="대표이메일"
              name="email"
              v-model="data.email">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
            <c-text
              :editable="editable"
              label="담당자명"
              name="chargeName"
              v-model="data.chargeName">
            </c-text>
          </div>
          <!-- <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
            <c-checkbox
              :editable="false"
              :isFlag="true"
              label="사용여부"
              name="useFlag"
              v-model="data.useFlag"
            />
          </div> -->
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              :editable="editable"
              label="본사주소"
              name="address"
              v-model="data.address">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-multi-select
              :disabled="true"
              :editable="editable"
              codeGroupCd="PLANT_CD"
              itemText="codeName"
              itemValue="code"
              label="관련사업장"
              name="plantCds"
              v-model="data.plantCds">
            </c-multi-select>
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-upload
              :attachInfo="attachInfoUp"
              :editable="editable"
              label="작업계획서">
            </c-upload>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-upload
              :attachInfo="attachInfo3"
              :editable="editable"
              label="교육일지">
            </c-upload>
          </div> -->
        </template>
      </c-card>
    </q-form>
    <component
      :is="vendorUser"
    />
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'vendor-detail',
  props: {
  },
  data() {
    return {
      // attachInfoUp: {
      //   isSubmit: '',
      //   taskClassCd: 'JOB_PLAN',
      //   taskKey: '',
      // },
      // attachInfo3: {
      //   isSubmit: '',
      //   taskClassCd: 'EDUCATION_JOURNAL',
      //   taskKey: '',
      // },
      data: {
        vendorCd: '',  // 업체코드
        vendorName: '',  // 업체명
        vendorClassCd: null,  // 업체분류 코드
        vendorTypeCd: null,  // 업체유형 코드
        address: '',  // 본사주소
        bizNo: '',  // 사업자번호
        chargeDeptCd: '',  // 담당부서 코드
        phoneNo: '',  // 대표 전화번호
        email: '',  // 대표 이메일
        chargeName: '',  // 담당자명
        representName: '',  // 대표자명
        portalConnFlag: 'Y',  // 협력업체포탈 접속 여부
        portalId: '',  // 포탈 ID
        plantCds: '',  // 관련사업장
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        vendorUser: '',
      },
      vendorUser: () => import(`${'./vendorUser.vue'}`),
      editable: true,
      isSave: false,
      detailUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.cim.vendor.get.url;
      this.saveUrl = transactionConfig.mdm.cim.vendor.update.url;
      // code setting
      this.getDetail();
    },
    getDetail() {
      // 상세조회
      this.$http.url = this.$format(this.detailUrl, this.$store.getters.user.userId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.data, _result.data)

        // this.$set(this.attachInfoUp, 'taskKey', _result.data.vendorCd)
        // this.$set(this.attachInfo3, 'taskKey', _result.data.vendorCd);
      },
      () => {
      });
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId
              this.data.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>
